<template>
  <div class="c-multiple-choice-page">
    <div class="c-multiple-choice-page__question">
      <dynamic-entry
      v-for="(entry) in pageData.Sections[0].entries"
      :key="entry.ID"
      :entryData="entry" :id="'entry-' + entry.ID">
      </dynamic-entry>

      <ul class="c-multiple-choice-page__answers" v-if="answerSectionID == null">
        <li v-for="section in pageData.Sections.filter((item, index) =>  index > 0 && item.Title > '')" :key="section.ID">
          <button class="c-multiple-choice-page__answer" @click="selectAnswer(section.ID)">
            <span class="xp xp--medium xp-arrow-forward"></span><span class="question-title">{{ section.Title }}</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="o-section" v-for="sectionData in pageData.Sections.filter((item, index) => index > 0 && item.Title > '')" :key="sectionData.ID" :class="{ answer: true, selected: sectionData.ID == answerSectionID }">
      <div class="c-multiple-choice-page__answer">
        <span class="question-title">{{ sectionData.Title }}</span>
      </div>
      <dynamic-entry v-for="entry in sectionData.entries" :entryData="entry" :key="entry.ID" :id="'entry-' + entry.ID"></dynamic-entry>
    </div>
  </div>
</template>

<script>
// import XpMultipleChoicePage from '@xpedeo/core/dist/XpMultipleChoicePage'

import { pageMixin } from '@xpedeo/core/'

export default {
  mixins: [pageMixin],
  data () {
    return {
      answerSectionID: null
    }
  },
  mounted () {
    // TODO: if (!settings.repeatMultipleChoiceQuestions) this.answerSectionID = getItFromStore
  },
  methods: {
    selectAnswer (sectionID) {
      this.answerSectionID = sectionID
      // TODO: save Answer to store
    }
  }
}
</script>
