<template>
    <div v-if="nfcSupported">
      <div v-if="selectedLangCode == null && languageMenuItems.length > 1" class="c-select-language-page">
          <ul class="c-pages-menu menu">
              <li class="c-select-language-page__menu-item menu__item link" v-for="language in this.languageMenuItems" :key="language.id">
                  <a href="#" class="c-select-language-page__link" role="link" @click.prevent="setAppLang(language)">
                      <div class="c-select-language-page__name">{{ language.langName }}</div>
                      <img :src="language.icon" :alt="language.langName" class="c-select-language-page__icon" aria-hidden="true">
                  </a>
              </li>
          </ul>
      </div>
      <div v-else-if="nfcCodeFound" class="c-nfc-page">
          <ul class="c-pages-menu menu">
              <li class="c-nfc-page__menu-item menu__item link" v-for="item in branches" :key="item.id">
                  <a href="#" class="c-nfc-page__link" v-if="item.children.length == 0" role="link" @click.prevent="goToPage(item)">
                      <div class="c-nfc-page__name">{{ item.title }}</div>
                  </a>
                  <a href="#" v-if="item.children.length" :class="(isInSelectedBranch(item) ? 'selected ' : '') + 'c-nfc-page__name'" role="link" @click.prevent="clickBranch(item)">
                      <div class="c-nfc-page__name">{{ item.title }}</div>
                  </a>
                  <ul v-if="item.children.length" class="c-pages-menu menu">
                      <li class="c-nfc-page__menu-item menu__item link" v-for="subitem in item.children" :key="subitem.id">
                          <a href="#" class="c-nfc-page__link" v-if="subitem.children.length == 0" role="link" @click.prevent="goToPage(subitem)">
                              <div :class="(isInSelectedBranch(item) ? 'selected ' : '') + 'c-nfc-page__name'">{{ subitem.title }}</div>
                          </a>
                          <div v-if="subitem.children.length" class="c-nfc-page__name">{{ subitem.title }}</div>
                          <ul v-if="subitem.children.length" class="c-pages-menu menu">
                              <li>too deep</li>
                          </ul>
                      </li>
                  </ul>
              </li>
          </ul>
      </div>
      <div v-else-if="$route.query.nfcCode" class="c-nfc-page">
          <xp-section class="c-nfc-page__not-found">
              <div class="js-entry o-entry c-text-entry">
                  <div class="c-text-entry__content">
                      Dem NFC code {{ $route.query.nfcCode }} ist kein Inhalt zugeordnet.
                  </div>
              </div>
          </xp-section>
      </div>
      <div v-else class="c-nfc-page">
          <xp-section v-for="section in pageData.Sections" :key="section.ID">
              <dynamic-entry v-for="entry in section.entries" :entryData="entry" :key="entry.ID" :id="'entry-' + entry.ID"></dynamic-entry>
          </xp-section>
          <div class="c-nfc-page__nfc-ios-browser-hint" v-if="$xp.device.platform === 'browser' && pageData.Settings.nfcIosBrowserHint && $xp.device.isIPhoneOrPad">
            {{pageData.Settings.nfcIosBrowserHint}}
          </div>
      </div>
    </div>
    <div class="c-nfc-page__error-message" v-else>
      {{ $t('global.nfcNotAvailable') }}
    </div>
</template>

<script>
import { pageMixin } from '@xpedeo/core/'

export default {
  mixins: [pageMixin],
  data () {
    return {
      nfcCode: null,
      selectedLangCode: null,
      selectedBranchIds: [],
      branches: [],
      nfcCodeFound: false,
      nfcSupported: true
    }
  },
  mounted () {
    console.log('xpnfcPage mounted', this, this.$router)
    if ((this.$xp.device.platform === 'ios')) {
      if (!this.$xp.nfc.isEnabled) {
        this.nfcSupported = false
        return
      } else {
        console.log('On iOS, nfc is enabled & supported')
        this.$xp.nfc.scanNdefIos()
      }
    }

    this.$xp.navigation.preventAutoHide()
    this.$xp.navigation.showNav()
    window.localStorage.setItem('nfcPageId', this.pageData.ID)

    this.selectedLangCode = window.localStorage.getItem('nfcLangCode')
    this.selectedBranchIds = window.localStorage.getItem('nfcSelectedBranchIds') || []
    this.startNfcNavigation()
  },
  methods: {
    startNfcNavigation () {
      console.log('starting nfc navigation')
      console.log(this.$route, this.selectedLangCode, this.selectedBranchIds, this.$route.query.nfcCode)
      if (this.$route.query.nfcCode) {
        console.log('checking nfcCode')
        this.nfcCode = this.$route.query.nfcCode
        if (typeof this.nfcCode === 'string' && parseInt(this.nfcCode) > 0 && parseInt(this.nfcCode).toString() === this.nfcCode) {
          console.log(this.nfcCode)
          console.log('checking nfcCode  2')
          this.nfcCode = parseInt(this.nfcCode)
        }
        console.log('should build branches')
        this.buildBranches()
      }
    },
    setAppLang (lang) {
      window.localStorage.setItem('nfcLangCode', lang.langCode)
      this.$xp.polyglot.setLanguage(lang.langCode, lang.path)
      this.selectedLangCode = lang.langCode
      this.$router.push('/' + lang.path + 'page/' + this.pageData.ID + '?nfcCode=' + this.$route.query.nfcCode)
    },
    goToPage (item) {
      console.log('goToPage', item)
      const selectedBranchIds = []
      let p = item
      while (p.ancestorId) {
        selectedBranchIds.push(p.ancestorId)
        p = this.nfcData.ancestors[p.ancestorId]
      }
      window.localStorage.setItem('nfcSelectedBranchIds', selectedBranchIds)
      this.$router.push('/page/' + item.id)
    },
    clickBranch (item) {
      if (item.children.length) {
        this.goToPage(item.children[0])
      }
    },
    isInSelectedBranch (item) {
      return this.selectedBranchIds.includes(item.id) || this.selectedBranchIds.includes(item.ancestorId)
    },
    buildBranches () {
      console.log('build Branches', this.nfcData.pages[this.nfcCode])
      if (this.nfcData.pages[this.nfcCode]) {
        if (this.nfcData.pages[this.nfcCode].length === 1 && this.nfcData.autoStart) {
          this.goToPage(this.nfcData.pages[this.nfcCode][0])
        }

        this.nfcData.pages[this.nfcCode].forEach((item) => {
          this.nfcCodeFound = true
          this.addBranchItem(item)
        })
      }
    },
    addBranchItem (item) {
      item = Object.assign({}, item)
      if (!item.children) {
        item.children = []
      }
      if (item.ancestorId) {
        const found = this.branches.find(function (x) {
          return x.id === item.ancestorId
        })

        if (found) {
          found.children.push(item)
        } else if (this.nfcData.ancestors[item.ancestorId]) {
          const ancestor = this.nfcData.ancestors[item.ancestorId]
          const added = this.addBranchItem(ancestor)
          added.children.push(item)
        }
      } else {
        this.branches.push(item)
      }
      return item
    }
  },
  computed: {
    nfcData () {
      return this.pageData.nfc
    },
    languageMenuItems () {
      const items = []
      this.pageData.LanguageVirtualFolders.forEach((item) => {
        items.push({
          id: item.ID,
          langName: item.LangName,
          langCode: item.LangCode,
          langId: item.LangID,
          path: item.Path,
          icon: item.Icon
        })
      })
      return items
    }
  },
  watch: {
    $route: {
      handler: function (newValue, oldValue) {
        if (newValue.path === oldValue.path && newValue.query !== null) {
          this.startNfcNavigation()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .o-page{
        &.is-padded-top.is-full-screen {
            padding-top: calc(#{$nav-size} + 2px);
        }
    }
    .c-app--ios {
        .c-nfc-page {
            position: relative;
        }
    }
    .c-nfc-page__link {
        padding: 0;
    }
    .c-nfc-page__name {
        padding: 0;
    }
    .c-nfc-page__name.selected {
        display: inline-block;
        padding: 3px 12px;
        background: rgba(map-get($light-theme-colors, "body-bg-color"), 0.5);
    }

    li.c-nfc-page__menu-item {
        padding: 12px 0 6px calc(#{$nav-size} + 14px);
        li.c-nfc-page__menu-item {
            background-color: transparent;
            border: 0px;
            min-height: unset;
            padding-bottom: 12px;
            padding-left: $nav-size;
            padding-top: 6px;
        }
    }
</style>
